<template>
  <div class="welcome">
    <v-container>
      <div class="text-center mb-12">
        <img :src="$store.getters['app/logo']" height="80" />
      </div>
      <!-- <v-img src="@/assets/images/logo-welcome.svg" width="163" center class="mx-auto logo" /> -->
      <template v-if="userIsAuthorized">
        <div class="welcome-title">
          Привет, {{ user.first_name || 'Анонимный пользователь' }} !
        </div>
        <v-btn color="primary" block x-large class="mb-6" :to="{ name: 'Home' }"
          >Продолжить</v-btn
        >
        <v-btn text class="bottom-text" @click="logout"
          >Выйти из учетной записи</v-btn
        >
      </template>

      <template v-else>
        <v-btn
          color="primary"
          class="mb-6"
          depressed
          block
          x-large
          :to="{ name: 'Login' }"
          >Войти</v-btn
        >
        <div v-if="telegramButton" class="flex justify-center">
          <a
            :href="`https://t.me/${telegramButton}?start=${merchantKey}${deviceId}`"
            target="__blank"
            class="telegram-auth-btn external-link"
            @click="checkTelegramAuthStatus"
          >
            <img
              src="@/assets/images/social/telegram_white.svg"
              alt="telegram"
              class="telegram-icon"
            />
            Войти через Telegram
          </a>
        </div>
        <v-btn
          v-if="!$store.getters['app/alternativeAuth']"
          color="primary"
          class="mb-6"
          block
          x-large
          outlined
          :to="{ name: 'Registration' }"
          >Регистрация</v-btn
        >
        <v-btn text :to="{ name: 'Home' }">Войти без регистрации</v-btn>
      </template>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      intervalId: null,
    }
  },

  computed: {
    userIsAuthorized() {
      return this.$store.getters['user/isAuthorized']
    },

    user() {
      return this.$store.state.user && this.$store.state.user.profile
    },

    telegramButton() {
      return this.$store.state.app.settings.telegram_button
    },

    merchantKey() {
      return localStorage.getItem('merchantKey')
    },

    deviceId() {
      return localStorage.getItem('deviceId').slice(0, 32)
    },
  },

  methods: {
    logout() {
      this.$store.commit('user/logout')
    },

    checkTelegramAuthStatus() {
      this.intervalId = setInterval(async () => {
        const { details } = await this.$api.get('telegram_status')
        if (details) {
          clearInterval(this.intervalId)
          const { dispatch, commit } = this.$store
          commit('user/setToken', details.token)
          await dispatch('user/loadProfile')
          commit('snackbar/update', {
            active: true,
            text: 'Вы успешно авторизированы',
          })
          this.$router.push('/')
        }
      }, 2500)
    },
  },
}
</script>

<style lang="scss" scoped>
.welcome-title {
  // color: #fff;
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 30px;
}

.logo {
  margin-bottom: 100px;
}

.welcome {
  text-align: center;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  background: #333333 url(~@/assets/images/welcome-background.svg) no-repeat
    center;
  background-size: cover; */
}

.bottom-text {
  text-decoration: underline;
  // color: #b8b8b8;
}

.telegram-auth-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 16px;
  margin-bottom: 24px;
  border-radius: 16px;
  text-decoration: none;
  background-color: hsl(200, 74%, 51%);
  color: #ffff;
  &:hover {
    background-color: hsl(200, 74%, 61%);
  }
  &:focus {
    background-color: hsl(200, 74%, 46%);
  }
}

.telegram-icon {
  height: 20px;
}
</style>
